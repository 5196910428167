:root {
    --transform-x:translateX(-50%);
    --position:absolute;
    --cursorToEdit:auto;
    --bankeeColor:#D2DAF9;

}

.main {
    position: relative;
    width:100%;
    border:2px solid var(--bankeeColor,#D2DAF9);
    height: 200px;
    border-radius: 16px;
    font-weight: 500;
    color:#0C0C0E;
    padding:18px 16px;
}

.main:focus-within {
    border: 2px solid #8595F4;
}

.mainRead {
    width:100%;
    font-weight: 500;
    color:#0C0C0E;
}

.ql-container {
    position:relative;
    font-size:16px;
    font-family: 'Quicksand', sans-serif;
}

.ql-editor {
    padding:0 10px;
}

.ql-editor > * {
    cursor: var(--cursorToEdit, auto);
}

.main:hover {
    transition:all.3s;
    border:2px solid #8595F4;
    box-shadow: 0 0 0 4px #D2DAF9;
}

.ql-bubble .ql-tooltip-arrow {
    display:none;
}

.ql-tooltip {
    left: 50% !important;
    width:550px;
    max-width: 100%;
    top: unset!important;
    bottom:180px;
    margin-top:0!important;
}

.ql-bubble .ql-tooltip {
    transform: var(--transform-x, translateX(-50%));
    position: var(--position, absolute);
    z-index: 2;
}

.ql-bubble .ql-tooltip-editor input[type=text] {
    height:unset;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
    position:unset!important;
    display: contents;
    white-space: nowrap;
}

.ql-container.ql-bubble:not(.ql-disabled) a::after{
    border-top: unset!important;
}

.ql-container.ql-bubble:not(.ql-disabled) a::before{
    top:0!important;
    left:50%;
    margin-left: 0;
}

.ql-bubble .ql-toolbar .ql-formats {
    margin:5px 10px
}

.ql-bubble .ql-toolbar .ql-formats:first-child{
    margin-left: 10px!important;
}

.ql-editor ul {
    padding-left: 0;
}

.main .form-input:focus-within ~ .form-input-label,
.active-label .form-input ~ .form-input-label
{
    top: -12px;
    left: 15px;
    font-size: 10px;
    color:#82818C;
    z-index: 1;
    opacity: .5;
    font-weight: 700;
}

.active-focus-label:focus-within::after, .active-label::after {
    position: absolute;
    top: -2px;
    left:10px;
    content: '';
    background: #fff;
    width: 90px;
    height: 2px;
}

.main .form-input-label {
    font-family: Quicksand,sans-serif;
    color: #82818C;
    font-size: 12px;
    position: absolute;
    pointer-events: none;
    opacity: .5;
    font-weight: 700;
    left: 8px;
    top: 8px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
}
