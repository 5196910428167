@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap");
@import "./animations.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

/* Works on Firefox */
/*.smooth-scrollbar {*/
/*  scrollbar-width: thin;*/
/*  scrollbar-color: rgba(232, 232, 232, 0.5) rgba(232, 232, 232, 0.5);*/
/*}*/

/* Works on Chrome, Edge, and Safari */
.smooth-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.smooth-scrollbar::-webkit-scrollbar-track {
  background: rgba(232, 232, 232, 0.5);
}

.smooth-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(170, 170, 170, 1);
  border-radius: 20px;
}

/* Works on Firefox */
/*.invisible-scrollbar {*/
/*  scrollbar-height: none;*/
/*  scrollbar-width: none;*/
/*}*/

/* Works on Chrome, Edge, and Safari */
.invisible-scrollbar::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

@supports not selector(::-webkit-scrollbar) {
  .smooth-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(232, 232, 232, 1) rgba(232, 232, 232, 0.5);
  }
  .invisible-scrollbar {
    scrollbar-height: none;
    scrollbar-width: none;
  }
}
