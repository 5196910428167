.animation-fade-enter {
  opacity: 0;
}
.animation-fade-enter-active {
  opacity: 1;
  transition: all 700ms ease-in;
}
.animation-fade-exit {
  opacity: 1;
  pointer-events: none;
}
.animation-fade-exit-active {
  opacity: 0;
  transition: all 700ms ease-in;
}
